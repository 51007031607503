import { Container, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useLinkTo, useRoute } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Animated, Image, StyleSheet, Text } from "react-native";
import { navigationStore } from "../../app/stores/Navigation";
import { BasicButton } from "../../components/BasicButton";
import { Footer } from "../../components/Footer";
import { HeaderIcon } from "../../components/HeaderIcon";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { AccentColor, FastAnimationTime } from "../../utils/Constants";

export const AboutUs = () => {
	const route = useRoute();
	const linkTo = useLinkTo();

	const [bgColorAnimation] = useState(new Animated.Value(0));
	const [bgOpacityAnimation] = useState(new Animated.Value(0));
	const [heroOpacityAnimation] = useState(new Animated.Value(1));

	const currentScreen = navigationStore((state) => state.currentScreen);
	const updateSplashLoaded = navigationStore((state) => state.updateSplashLoaded);
	const updateCurrentScreen = navigationStore((state) => state.updateCurrentScreen);
	const updateEnabledNavBg = navigationStore((state) => state.updateEnabledNavBg);
	const enabledNavBg = navigationStore((state) => state.enabledNavBg);

	useEffect(() => {
		if (currentScreen === ScreenComponent.AboutUs && enabledNavBg) startLearnMoreJourney();
		// startLearnMoreJourney();
	}, [enabledNavBg, currentScreen]);

	const startLearnMoreJourney = () => {
		Animated.timing(heroOpacityAnimation, {
			toValue: 0,
			duration: FastAnimationTime,
			useNativeDriver: false,
		}).start(() => {
			Animated.parallel([
				Animated.timing(bgColorAnimation, {
					toValue: 1,
					duration: FastAnimationTime,
					useNativeDriver: false,
				}),
				Animated.timing(bgOpacityAnimation, {
					toValue: 1,
					duration: FastAnimationTime,
					useNativeDriver: false,
				}),
			]).start(() => {
				updateEnabledNavBg(true);
				updateSplashLoaded(true);
			});
		});
	};

	const NavigationToScreen = (screen: ScreenComponent) => {
		console.log("navigating -> " + screen.toLowerCase().replaceAll("_", ""));
		linkTo(`/${screen.toLowerCase().replaceAll("_", "")}`);
		updateCurrentScreen(screen);
	};

	const HeroIntro = () => {
		return (
			<Animated.View
				style={{
					flex: 1,
					flexDirection: "column",
					justifyContent: "center",
					alignSelf: "center",
					opacity: heroOpacityAnimation,
					display: enabledNavBg ? "none" : "flex",
				}}
			>
				<Grid
					id="intro-container"
					container
					justifyContent="center"
					alignItems="center"
					flexDirection={"row"}
					maxWidth={"xl"}
					textAlign={{ xs: "center", lg: "left" }}
				>
					{/* Component One */}
					<Grid
						container
						item
						xs={12}
						md={5}
						sx={{ display: { xs: "none", lg: "flex" } }}
					>
						<Image
							style={{ aspectRatio: 1 / 1, width: 450, resizeMode: "contain" }}
							source={require("../../assets/images/vector-illustration/intro.png")}
						/>
					</Grid>

					{/* Divider */}
					<Divider
						flexItem
						orientation="vertical"
						sx={{
							display: { xs: "none", lg: "flex" },
							backgroundColor: "white",
							marginX: 11,
							height: "40%",
							alignSelf: "center",
						}}
					/>
					{/* Component Two */}
					<Grid
						container
						item
						xs={12}
						md={5}
						flexDirection={"column"}
					>
						<Grid
							item
							sx={{ width: 200, alignSelf: { xs: "center", lg: "flex-start" } }}
						>
							<Image
								style={{
									aspectRatio: 1 / 1,
									tintColor: "white",
									resizeMode: "contain",
								}}
								source={require("../../assets/images/logo-solo.png")}
							/>
						</Grid>
						<Grid>
							<Text
								style={{
									...styles.subtitle,
									color: "white",
									fontWeight: "bold",
									fontSize: 52,
									lineHeight: 46,
									textAlign: "center",
								}}
							>
								{`SCHWIN LOGISTICS`}
							</Text>
						</Grid>
						<Grid>
							<Text
								style={{
									...styles.subtitle,
									color: "white",
									fontWeight: "bold",
									fontSize: 22,
									lineHeight: 46,
									textAlign: "center",
								}}
							>
								{`Efficient  •  Safe  •  Reliable`}
							</Text>
						</Grid>
						<Grid mt={4}>
							<BasicButton
								title={"Learn more about us"}
								variant={"text"}
								sx={{ paddingX: 4 }}
								style={{
									borderRadius: 45,
									height: 45,
									color: "white",
									alignSelf: "flex-end", // Align button to the right
								}}
								onClick={() => startLearnMoreJourney()}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Animated.View>
		);
	};

	const Intro = () => {
		return (
			<Grid>
				<Grid
					container
					alignItems="center"
					flexDirection="column"
					gap={2}
					mb={1}
					mt={8}
				>
					<HeaderIcon headerIconName="group" />
					<Grid
						item
						xs={12}
						textAlign="center"
					>
						<Text style={styles.title}>About Us</Text>
					</Grid>
				</Grid>

				<Grid
					container
					flexDirection="row"
					justifyContent={"center"}
				>
					<Grid
						item
						justifyContent={"center"}
						alignContent={"center"}
						marginY={4}
					>
						<Image
							source={require("../../assets/images/icons/sa-flag.jpg")}
							style={{ aspectRatio: 1 / 1, borderRadius: "50%", width: 60 }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						textAlign="center"
						maxWidth={"md"}
					>
						<Text style={styles.subtitle}>
							{`Schwin Logistics is a proudly South African local and long distance transporter.\nWe offer reliable customer-centric transportation services.`}
						</Text>
					</Grid>
				</Grid>

				<Grid
					container
					gap={{ xs: 1.5, md: 2 }}
					justifyContent={"center"}
					alignContent={"center"}
					mt={6}
				>
					<Grid
						item
						borderRadius={4}
						overflow={"hidden"}
						width={{ xs: 240, md: 350 }}
						// sx={{ backgroundColor: "#f2f2f2" }}
					>
						<Image
							source={require("../../assets/images/gallery/8.jpeg")}
							style={{ aspectRatio: 16 / 11 }}
						/>
					</Grid>
					<Grid
						item
						borderRadius={4}
						overflow={"hidden"}
						width={{ xs: 240, md: 350 }}
						// sx={{ backgroundColor: "#f2f2f2" }}
					>
						<Image
							source={require("../../assets/images/gallery/15.jpeg")}
							style={{ aspectRatio: 16 / 11 }}
						/>
					</Grid>
					<Grid
						item
						borderRadius={4}
						overflow={"hidden"}
						width={{ xs: 240, md: 350 }}
						// sx={{ backgroundColor: "#f2f2f2" }}
					>
						<Image
							source={require("../../assets/images/gallery/7.jpeg")}
							style={{ aspectRatio: 16 / 11 }}
						/>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const OurVision = () => {
		return (
			<Grid
				container
				flexDirection="row"
				justifyContent={"center"}
				gap={4}
				mt={6}
			>
				<HeaderIcon headerIconName={"medal"} />
				<Grid
					item
					container
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={4}
				>
					<Text style={styles.title}>Our Vision</Text>
					<Text style={styles.subtitle}>
						{`Our aim is to provide efficient and dependable transport solutions that add value to our clients, helping them build and maximize sustainable competitive advantage. We do this by growing our business through safe operations, innovation and continued excellence, while offering an exceptional delivery experience.`}
					</Text>
				</Grid>

				<Grid
					item
					container
					justifyContent={"center"}
					gap={{ xs: 4, md: 12 }}
					mt={4}
				>
					{/* First Pair */}
					<Grid
						item
						gap={12}
					>
						<Grid
							item
							container
							mb={2}
							justifyContent={"center"}
							alignContent={"center"}
							border={3}
							padding={4.5}
							borderColor={AccentColor}
							sx={{
								width: 150,
								aspectRatio: 1 / 1,
								overflow: "hidden",
								borderRadius: 75,
								// backgroundColor: AccentColor,
							}}
						>
							<Image
								source={require("../../assets/images/icons/protection.png")}
								style={styles.imageSection}
							/>
						</Grid>
						<Grid
							item
							container
							justifyContent={"center"}
						>
							<Text style={{ ...styles.subtitle, fontWeight: "600" }}>Safe Operations</Text>
						</Grid>
					</Grid>

					{/* Second Pair */}
					<Grid
						item
						alignItems="center"
					>
						<Grid
							item
							container
							mb={2}
							justifyContent={"center"}
							alignContent={"center"}
							border={3}
							padding={4.5}
							borderColor={AccentColor}
							sx={{
								width: 150,
								aspectRatio: 1 / 1,
								overflow: "hidden",
								borderRadius: 75,
								// backgroundColor: AccentColor,
							}}
						>
							<Image
								source={require("../../assets/images/icons/opportunity.png")}
								style={styles.imageSection}
							/>
						</Grid>
						<Grid
							item
							container
							justifyContent={"center"}
						>
							<Text style={{ ...styles.subtitle, fontWeight: "600" }}>Innovation</Text>
						</Grid>
					</Grid>

					{/* Third Pair */}
					<Grid
						item
						justifyContent={"center"}
						alignContent={"center"}
						alignItems={"center"}
						alignSelf={"center"}
						justifyItems={"center"}
						justifySelf={"center"}
					>
						<Grid
							item
							mb={2}
							justifyContent={"center"}
							alignContent={"center"}
							border={3}
							padding={4.5}
							borderColor={AccentColor}
							sx={{
								width: 150,
								aspectRatio: 1 / 1,
								overflow: "hidden",
								borderRadius: 75,
								// backgroundColor: AccentColor,
							}}
						>
							<Image
								source={require("../../assets/images/icons/enlargement.png")}
								style={styles.imageSection}
							/>
						</Grid>
						<Grid
							item
							justifyContent={"center"}
						>
							<Text style={{ ...styles.subtitle, fontWeight: "600" }}>Continued Excellence</Text>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const OurMission = () => {
		return (
			<Grid
				container
				flexDirection="row"
				justifyContent={"center"}
				gap={4}
				mt={6}
			>
				<HeaderIcon headerIconName={"expert"} />
				<Grid
					item
					container
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={4}
				>
					<Text style={styles.title}>Our Mission</Text>
					<Text style={styles.subtitle}>
						{`We aim to be the transport company of choice and leader in providing quality services to our clients. At Schwin Logistics our commitment to investment in state of the art business intelligence, visibility and tracking technology ensures your cargo arrives at its destination safely and on time. We strive to operate in a responsible manner for both our clients and employees.`}
					</Text>
				</Grid>
			</Grid>
		);
	};

	const Services = () => {
		const serviceItems = [
			{
				icon: "distance",
				title: "Local and Long Distance Transportation",
				description: "We provide transportation services to destinations throughout South Africa.",
			},
			{
				icon: "product",
				title: "Containerized Cargo",
				description:
					"Efficient handling and transportation of containerized cargo for your convenience.",
			},
			{
				icon: "boxes",
				title: "Break Bulk Cargo",
				description: "Safe and reliable transport of break bulk cargo to meet your specific needs.",
			},
			{
				icon: "hazard",
				title: "Hazardous and Specialized Chemicals",
				description:
					"HAZCHEM and SQAS accredited transport for hazardous and specialized chemicals.",
			},
		];

		const renderServiceItem = (item, index) => (
			<Grid
				key={index}
				item
				xs={12}
				md={2.5}
				mt={4}
			>
				<Grid
					container
					direction="column"
					alignItems="center"
					maxWidth={"md"}
				>
					<Grid
						item
						mb={2}
					>
						<HeaderIcon headerIconName={item.icon} />
					</Grid>
					<Grid
						item
						style={{ minHeight: 100, textAlign: "center" }}
					>
						<Text style={styles.serviceTitle}>{item.title}</Text>
					</Grid>
					<Grid
						item
						style={{ textAlign: "center" }}
					>
						<Text style={styles.serviceDescription}>{item.description}</Text>
					</Grid>
				</Grid>
			</Grid>
		);

		return (
			<Grid
				container
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				mt={14}
				mb={18}
			>
				<HeaderIcon headerIconName={"handshake"} />
				<Grid
					item
					container
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					gap={4}
				>
					<Text style={styles.title}>Our Services</Text>
					<Text style={styles.subtitle}>
						Explore our diverse transport services designed for efficiency and reliability. Schwin
						Logistics is committed to providing high-quality, customized solutions that prioritize
						both our partners and the environment.
					</Text>
				</Grid>
				<Grid
					container
					my={10}
					gap={3}
					justifyContent={"center"}
				>
					{serviceItems.map((item, index) => renderServiceItem(item, index))}
				</Grid>
			</Grid>
		);
	};

	const AboutUsContent = () => {
		return (
			<Container
				component="main"
				maxWidth="lg"
			>
				<Intro />

				<Grid
					item
					mt={8}
				>
					<Divider
						orientation="horizontal"
						style={{ flex: 1, height: 2 }}
					/>
				</Grid>

				<OurMission />

				<Grid
					item
					mt={8}
				>
					<Divider
						orientation="horizontal"
						style={{ flex: 1, height: 2 }}
					/>
				</Grid>

				<OurVision />

				<Grid
					item
					mt={8}
				>
					<Divider
						orientation="horizontal"
						style={{ flex: 1, height: 2 }}
					/>
				</Grid>

				<Services />
			</Container>
		);
	};

	return (
		<>
			<Animated.ScrollView
				style={{
					backgroundColor: bgColorAnimation.interpolate({
						inputRange: [0, 1],
						outputRange: ["rgba(255, 255, 255, 0.0)", "rgba(255, 255, 255, 0.90)"],
					}),
					// backgroundColor: "transparent",
				}}
				contentContainerStyle={{
					...styles.container,
					justifyContent: enabledNavBg ? "flex-start" : "center",
				}}
			>
				<HeroIntro />
				<Animated.View
					style={{
						...styles.background,
						display: enabledNavBg ? "flex" : "none",
						opacity: bgOpacityAnimation,
						backgroundColor: "transparent",
					}}
				>
					<AboutUsContent />
					<Footer />
				</Animated.View>
			</Animated.ScrollView>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "column",
	},
	background: {
		flex: 1,
	},
	horizonalDivider: {
		width: 150,
		alignSelf: "center",
	},
	verticalDivider: {
		margin: 12,
		height: 15,
		alignSelf: "center",
	},
	image: {
		width: 300,
		aspectRatio: 1 / 1,
		resizeMode: "contain",
		alignSelf: "center",
		tintColor: "white",
	},
	imageSection: {
		flex: 1,
		aspectRatio: 1 / 1,
	},
	detailSection: {
		alignSelf: "center",
		flexDirection: "column",
	},
	detail: {
		fontSize: 18,
		fontWeight: "400",
		lineHeight: 32,
		letterSpacing: 0.5,
		fontFamily: "secondaryFont",
	},
	getInTouchText: {
		marginVertical: 28,
		color: "white",
		fontSize: 16,
		fontWeight: "600",
	},
	title: {
		fontSize: 46,
		fontWeight: "400",
		fontFamily: "primaryFont",
		color: "black",
		letterSpacing: 0.5,
	},
	subtitle: {
		fontSize: 18,
		fontWeight: "300",
		fontFamily: "secondaryFont",
		color: "grey",
		letterSpacing: 0.5,
		textAlign: "center",
		lineHeight: 32,
	},
	serviceTitle: {
		fontSize: 20,
		fontWeight: "600",
		fontFamily: "primaryFont",
		color: "black",
		letterSpacing: 0.5,
		textAlign: "center",
	},
	serviceDescription: {
		fontSize: 16,
		fontWeight: "300",
		fontFamily: "secondaryFont",
		lineHeight: 20,
		color: "grey",
		letterSpacing: 0.5,
		textAlign: "right",
	},
});
